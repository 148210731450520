<template>
  <div id="app">
    <SushiPopup :visible="isPopupVisible" @close-popup="isPopupVisible = false" />
    <header class="header">
      <div class="logo" @click="navigateToMainPage">
        <img src="./assets/logo_compress.png" alt="Суші Токаш">
        <span>{{ $t('main-page.logo.title') }}</span>
      </div>
      <nav class="navigation" :class="{ open: isMenuOpen }">
        <ul>
          <li><a href="#">{{ $t('main-page.nav.menu') }}</a></li>
          <li><a href="#sales">{{ $t('main-page.nav.sales') }}</a></li>
          <li><a href="#about">{{ $t('main-page.nav.about') }}</a></li>
          <li><a href="#contact">{{ $t('main-page.nav.contact') }}</a></li>
          <li v-if="isSmallScreen"><a href="#/installation-instructions">{{ $t('main-page.nav.app') }}</a></li>
        </ul>
      </nav>
      <div class="mock"></div>
      <div class="icons">
        <div class="language-dropdown">
          <div class="selected-language" @click="toggleLanguageDropdown" v-if="!isOnlySmallScreen">
            <img v-if="this.$i18n.locale === 'uk'" src="./assets/icons/ua_localization.svg" 
                alt="UA" 
                class="language-icon" />
            <img v-if="this.$i18n.locale === 'en'" src="./assets/icons/en_localization.png" 
                alt="UA" 
                class="language-icon" />
            <span v-if="!isDropdownOpen">▼</span>
            <span v-if="isDropdownOpen">▲</span>
          </div>
          <div class="language-icon-container" v-if="isOnlySmallScreen">
            <img
              v-if="(this.$i18n.locale === 'uk') && isOnlySmallScreen"
              src="./assets/icons/ua_localization.svg"
              alt="UA"
              class="language-icon"
              @click="toggleLanguageDropdown"
            />
            <img
              v-if="(this.$i18n.locale === 'en') && isOnlySmallScreen"
              src="./assets/icons/en_localization.png"
              alt="EN"
              class="language-icon"
              @click="toggleLanguageDropdown"
            />
          </div>
          
          <div v-if="isDropdownOpen" class="dropdown-menu">
            <div class="dropdown-item" @click="switchLanguage('uk')">
              <img src="./assets/icons/ua_localization.svg" alt="UA" />
            </div>
            <div class="dropdown-item" @click="switchLanguage('en')">
              <img src="./assets/icons/en_localization.png" alt="EN" />
            </div>
          </div>
        </div>

        <img src="./assets/icons/Account_ic.png" class="img-acc" alt="Account" @click="goToAccount" />
      </div>
      <div class="burger-menu" @click="toggleMenu">
        <div :class="{ 'burger-bar': true, 'open': isMenuOpen }"></div>
        <div :class="{ 'burger-bar': true, 'open': isMenuOpen }"></div>
        <div :class="{ 'burger-bar': true, 'open': isMenuOpen }"></div>
      </div>
    </header>
    <div class="main-content">
      <div class="cart-button" v-if="!isOnAuthPage" @click="toggleCart">
        <span ref="cartIcon" class="cart-icon">🛒</span>
        <span class="cart-count">{{ totalItems }}</span>
      </div>
      <transition name="slide">
        <div v-if="showCart" class="cart-container">
          <ShoppingCart 
            :cartItems="cartItems" 
            @update-cart="updateCart" 
            @order-submitted="handleOrderSubmitted" 
            @close-cart="toggleCart" 
            @clear-cart="clearCart" />
        </div>
      </transition>
      <RouterViewWrapper @add-to-cart="addToCart"></RouterViewWrapper>
    </div>
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-logo" @click="navigateToMainPage">
          <img src="./assets/logo_compress.png" alt="Суші Токаш Logo">
          <h2>{{ $t('main-page.footer.title') }}</h2>
          <p>{{ $t('main-page.footer.tagline') }}</p>
          <div class="app-buttons"></div>
        </div>
        <div class="footer-nav">
          <h3>{{ $t('main-page.footer.nav-title') }}</h3>
          <ul>
            <li><a href="#">{{ $t('main-page.nav.menu') }}</a></li>
            <li><a href="#about">{{ $t('main-page.nav.about') }}</a></li>
            <li><a href="#sales">{{ $t('main-page.nav.sales') }}</a></li>
            <li><a href="#contact">{{ $t('main-page.nav.contact') }}</a></li>
          </ul>
        </div>
        <div class="footer-info">
          <h3>{{ $t('main-page.footer.order-title') }}</h3>
          <p><a href="tel:+380800338105">0 800 338 105</a></p>
          <h3 class="margin-top-h">{{ $t('main-page.footer.restaurant-hours') }}</h3>
          <p v-if="activeRestaurant">{{ activeRestaurant.workingHours.restaurant_hours.start }} - {{ activeRestaurant.workingHours.restaurant_hours.end }}</p>
          <p v-else>10:00 - 22:00</p>
          <h3 class="margin-top-h">{{ $t('main-page.footer.online-hours') }}</h3>
          <p v-if="activeRestaurant">{{ activeRestaurant.workingHours.delivery_time.start }} - {{ activeRestaurant.workingHours.delivery_time.end }}</p>
          <p v-else>11:00 - 21:30</p>
          <!-- <p class="margin-top-h">
            <a href="https://maps.app.goo.gl/ZL3VHh7NsPYLDGrh9">
              {{ $t('main-page.footer.address') }}
            </a>
          </p> -->
          <div v-if="activeRestaurants.length > 0" class="margin-top-h">
            <p v-for="restaurant in activeRestaurants" :key="restaurant.id">
              <a :href="restaurant.gmapsLink" target="_blank">{{ restaurant.address }}</a>
            </p>
          </div>
          <p v-else class="margin-top-h">
            <a href="https://maps.app.goo.gl/ZL3VHh7NsPYLDGrh9">{{ $t('main-page.footer.address') }}</a>
          </p>
        </div>
        <div class="footer-social">
          <h3>{{ $t('main-page.footer.social-title') }}</h3>
          <div class="social-icons">
            <a href="https://www.facebook.com/sushitokash/"><img src="./assets/facebook.png" alt="Facebook"></a>
            <a href="https://www.instagram.com/sushitokash/"><img src="./assets/instagram.png" alt="Instagram"></a>
            <a href="https://www.tiktok.com/@sushitokash1"><img src="./assets/TikTok.svg" alt="TikTok"></a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>


<script>
import axios from 'axios';
//import HelloWorld from './components/HelloWorld.vue'
import { sushiList } from './components/SushiItem.vue';
import ShoppingCart from './components/ShoppingCart.vue';
import RouterViewWrapper from './components/RouterViewWrapper.vue';
import SushiPopup from './components/SushiPopup.vue';

export default {
  name: 'App',
  components: {
    ShoppingCart,
    RouterViewWrapper,
    SushiPopup
    //HelloWorld
  },
  computed: {
    totalItems() {
      return this.cartItems.reduce((total, item) => total + item.quantity, 0);
    },
    isOnAuthPage() {
      // console.log(this.$route.name )
      return this.$route.name === 'login' || this.$route.name === 'registration' || this.$route.name === 'reset-password';
    },
    activeRestaurants() {
      return this.restaurantDetails ? this.restaurantDetails.filter(restaurant => restaurant.status === 'ACTIVE') : [];
    }
  },
  data() {
    return {
      isMenuOpen: false,
      sushiList: sushiList,
      cartItems: [],
      showCart: false,
      isPopupVisible: false, // Add this to control the popup visibility
      isOverloaded: false,
      isSmallScreen: false,
      isOnlySmallScreen: false,
      isDropdownOpen: false,
      restaurantDetails: [],
      salesDetails: null,
      activeRestaurant: null,

    };
  },
  mounted() {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      this.$i18n.locale = savedLanguage;
    }
    document.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('scroll', this.handleScroll);
    this.checkWorkingHours();
    this.checkOverloadStatus();
    this.fetchRestaurantDetails();
    this.fetchSalesDetails();

    this.notificationPermition();

    this.checkScreenWidth();
    this.loadActiveRestaurant();
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    loadActiveRestaurant() {
      const storedRestaurant = localStorage.getItem('activeRestaurant');
      if (storedRestaurant) {
        this.activeRestaurant = JSON.parse(storedRestaurant);
      }
    },
    toggleLanguageDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang);
      this.isDropdownOpen = false;  // Close the dropdown after selection
    },
    checkScreenWidth() {
      this.isSmallScreen = window.innerWidth < 600 || window.innerWidth > 750;
      this.isOnlySmallScreen = window.innerWidth < 600;
    },

    goToAccount() {
      this.$router.push('/account'); // Navigate to the account page
    },
    async notificationPermition(){
      // const permission = await Notification.requestPermission();
      //   if (permission === 'granted') {
      //     const registration = await navigator.serviceWorker.ready;

      //     const subscription = await registration.pushManager.subscribe({
      //       userVisibleOnly: true,
      //       applicationServerKey: this.urlBase64ToUint8Array('YOUR_PUBLIC_VAPID_KEY')
      //     });

      //     // Send the subscription details to the server to store it
      //     await this.saveSubscription(subscription);
      //   } else {
      //     console.log('User denied notifications');
      //   }
    },
    checkOverloadStatus() {
      const storedRestaurant = localStorage.getItem('activeRestaurant');
      
      if (storedRestaurant) {
        const activeRestaurant = JSON.parse(storedRestaurant);
        
        if (activeRestaurant.overload) {
          this.isOverloaded = true;
          return;
        }
      }
      axios
        .get('https://api.sushitokash.com.ua/api/status/ov_s')
        .then((response) => {
          if (response.data === true) {
            this.isOverloaded = true;
          } else {
            this.isOverloaded = false;
          }
        })
        .catch((error) => {
          console.error('Error fetching overload status:', error);
        });
    },
    fetchRestaurantDetails() {
      axios
        .get('https://api.sushitokash.com.ua/api/status/ov_rs')
        .then((response) => {
          this.restaurantDetails = response.data;

          // Save the restaurant details to local storage
          localStorage.setItem('restaurantDetails', JSON.stringify(this.restaurantDetails));

          // Check if the restaurantDetails array has more than one element
          if (this.restaurantDetails.length > 1) {
            // Find the restaurant with id: 1 and status "ACTIVE"
            const restaurantWithId1 = this.restaurantDetails.find(
              restaurant => restaurant.id === 1 && restaurant.status === "ACTIVE"
            );

            // Check if activeRestaurant is not set in localStorage and set it if restaurant with id: 1 exists and is "ACTIVE"
            if (!localStorage.getItem('activeRestaurant') && restaurantWithId1) {
              localStorage.setItem('activeRestaurant', JSON.stringify(restaurantWithId1));
              console.log('Active restaurant set to:', restaurantWithId1);
            }
            else {
              // If activeRestaurant exists in localStorage, find and update it from restaurantDetails
              const activeRestaurant = JSON.parse(localStorage.getItem('activeRestaurant'));
              if (activeRestaurant) {
                const updatedActiveRestaurant = this.restaurantDetails.find(
                  restaurant => restaurant.id === activeRestaurant.id
                );
                
                if (updatedActiveRestaurant) {
                  localStorage.setItem('activeRestaurant', JSON.stringify(updatedActiveRestaurant));
                  console.log('Updated active restaurant:', updatedActiveRestaurant);
                }
              }
            }
          } 
        })
        .catch((error) => {
          console.error('Error fetching restaurant details:', error);
        });
    },



    fetchSalesDetails() {
      axios
        .get('https://api.sushitokash.com.ua/api/status/ov_srs')
        .then((response) => {
          this.salesDetails = response.data.content;
          if (this.salesDetails.length > 1) {
            localStorage.setItem('salesDetails', JSON.stringify(this.salesDetails));
          }
        })
        .catch((error) => {
          console.error('Error fetching sales details:', error);
        });
    },



    navigateToMainPage() {
      window.location.href = '/';
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.showCart = false;
    },
    handleOutsideClick(event) {
      const menu = this.$el.querySelector('.navigation');
      const burger = this.$el.querySelector('.burger-menu');
      if (!menu.contains(event.target) && !burger.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },
    handleScroll() {
      this.isMenuOpen = false;
    },
    toggleCart() {
      this.showCart = !this.showCart;
    },
    checkWorkingHours() {
      const now = new Date().toLocaleString("en-US", { timeZone: "Europe/Kyiv" });
      const currentDate = new Date(now);
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // Define operating hours
      let openingHour = 11;
      let openingMinute = 0;
      let closingHour = 21;
      let closingMinute = 30;

      const activeRestaurant = JSON.parse(localStorage.getItem('activeRestaurant'));
      if (activeRestaurant && activeRestaurant.workingHours && activeRestaurant.workingHours.delivery_time) {
        // Parse delivery_time.start and delivery_time.end
        const [startHour, startMinute] = activeRestaurant.workingHours.delivery_time.start.split(':').map(Number);
        const [endHour, endMinute] = activeRestaurant.workingHours.delivery_time.end.split(':').map(Number);

        // Update operating hours using delivery_time
        openingHour = startHour;
        openingMinute = startMinute;
        closingHour = endHour;
        closingMinute = endMinute;
      }
      

      // Check if it's outside of operating hours
      if (
        currentHour < openingHour || 
        (currentHour === openingHour && currentMinute < openingMinute) ||
        currentHour > closingHour || 
        (currentHour === closingHour && currentMinute > closingMinute)
      ) {
        // Show the popup if it's outside operating hours
        this.isPopupVisible = true;
        return;
      }
    },

    addToCart(item) {
      const now = new Date().toLocaleString("en-US", { timeZone: "Europe/Kyiv" });
      const currentDate = new Date(now);
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // Define operating hours
      let openingHour = 11;
      let openingMinute = 0;
      let closingHour = 21;
      let closingMinute = 30;

      const activeRestaurant = JSON.parse(localStorage.getItem('activeRestaurant'));
      if (activeRestaurant && activeRestaurant.workingHours && activeRestaurant.workingHours.delivery_time) {
        // Parse delivery_time.start and delivery_time.end
        const [startHour, startMinute] = activeRestaurant.workingHours.delivery_time.start.split(':').map(Number);
        const [endHour, endMinute] = activeRestaurant.workingHours.delivery_time.end.split(':').map(Number);

        // Update operating hours using delivery_time
        openingHour = startHour;
        openingMinute = startMinute;
        closingHour = endHour;
        closingMinute = endMinute;
      }

      // Check if it's outside of operating hours
      if (
          currentHour < openingHour || 
          (currentHour === openingHour && currentMinute < openingMinute) ||
          currentHour > closingHour || 
          (currentHour === closingHour && currentMinute > closingMinute)
        ) {
        // Show the popup if it's outside operating hours
        this.isPopupVisible = true;
        //return;
      }
      if(this.isOverloaded === true){
        this.isPopupVisible = true;
        return;
      }

      const cartItem = this.cartItems.find(cartItem => cartItem.article === item.article);
      if (cartItem) {
        cartItem.quantity++;
      } else {
        this.cartItems.push({ ...item, quantity: 1 });
      }

      const cartButton = document.querySelector('.cart-button');
      cartButton.classList.add('visible');  // Show the cart button
      setTimeout(() => {
        cartButton.classList.remove('visible');  // Slide it back after 1 second
      }, 500);
    },
    updateCart(updatedItem) {
      const index = this.cartItems.findIndex(item => item.article === updatedItem.article);
      if (index !== -1) {
        this.cartItems.splice(index, 1, updatedItem);
      }
    },
    handleOrderSubmitted() {
      this.cartItems = [];
      this.showCart = false;
      alert('Дякуємо за замовлення, з вами св\'яжеться оператор для підтвердження');
    },
    clearCart() {
      this.cartItems = [];
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Nunito', sans-serif;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #353535;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 60px; /* Adjust based on the height of your fixed header */
  
}

.footer {
  background-color: #333;
  padding: 20px 0;
  color: #E9E9E9;
  text-align: center;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.footer-logo img {
  max-width: 150px; /* Set the maximum width for the logo */
  height: auto; /* Maintain the aspect ratio */
  display: block; /* Ensures proper alignment */
  margin: 0 auto; /* Center the image horizontally */
}
.footer-logo, .footer-nav, .footer-info, .footer-social {
  flex: 1;
}

.footer-nav ul {
  list-style-type: none;
  padding: 0;
}

.footer-nav ul li {
  margin: 5px 0;
}

.footer-nav ul li a {
  color: #E9E9E9;
  text-decoration: none;
}

.footer-nav ul li a:hover {
  text-decoration: underline;
}

.social-icons a {
  margin-right: 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}
.mock {
  display: none;
}
.account-icon img {
   width: 30px;
   height: auto;
   margin-left: 10px;
   margin-right: 5px;
   display: block; /* Ensures that the image behaves properly inside flexbox */
}
.icons{
  display: flex;
}
/* .account-icon {
  display: flex; 
  justify-content: center;
  align-items: center;
} */


/* Other existing styles... */

.img-acc{
  width: 40px !important;
}
@media (max-width: 600px) {
  .navigation,
  .icons {
    /* display: none; */
    margin-right: 5px
  }
  .mock {
    display: block;
    width: 24%;
  }
}


#app {
  font-family: Nunito, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #E9E9E9;
  height: 100%;
  margin: 0;
}

body {
  background: url('assets/Group75-min(1).png') center center repeat-y, #202020;
  background-size: cover;
  background-repeat: repeat-y;
  background-position: center center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  color: #E9E9E9;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #353535;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.icons {
  /* display: flex; */
  margin-right: 20px;
      min-width: 160px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.logo span {
  font-size: 24px;
  font-weight: bold;
  color: #E9E9E9;
  white-space: nowrap;
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navigation ul li {
  margin: 0 15px;
}

.navigation ul li a {
  color: #E9E9E9;
  text-decoration: none;
  font-size: 18px;
}

.icons img {
  width: 30px;
  height: auto;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  padding-right: 20px;
  z-index: 1001; /* Ensure it's above the nav */
}

.burger-bar {
  width: 30px;
  height: 3px;
  background-color: #A0D172;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.burger-bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-bar.open:nth-child(2) {
  opacity: 0;
}

.burger-bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Media queries for mobile */
@media (max-width: 600px) {
  .language-icon-container img {
    width: 25px !important;
  }
  .sushi-types a img {
    width: 30px !important;
  }
  .img-acc{
    width: 30px !important;
  }
  .dropdown-item img {
      width: 25px !important;
      height: 25px !important;
      border-radius: 50%;
  }
  .dropdown-menu {
    left: -5px !important;
    padding: 6px !important;
  }

  .navigation {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #353535;
  }

  .navigation.open {
    display: flex;
  }

  .burger-menu {
    display: flex;
  }

  .navigation ul {
    flex-direction: column;
    align-items: center;
  }

  .navigation ul li {
    margin: 10px 0;
  }
}



.sushi-section, .app-section, .promo-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
}

.sushi-section h2, .app-section h2, .promo-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}
.sushi-types-container {
  display: flex;
  justify-content: center;
  margin-top: 80px; /* Adjust this margin to avoid overlap with fixed header */
  width: 100%;
}

.sushi-types {
  display: flex;
  justify-content: space-between;
  width: 50%;  /* Set block width to 50% of the parent container */
  background-color: #333;
  padding: 5px 20px;
  border-radius: 10px;
  padding-top: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sushi-types a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Inherits the color from the parent element */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1; /* Allows the items to grow and take up equal space */
}

.sushi-types a img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.sushi-types a span {
  margin-top: 5px;
  font-size: 14px;
  color: #E9E9E9;
}


.sushi-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  /* background-color: #222;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.sushi-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}

.sushi-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.sushi-item {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sushi-item img {
  width: 100%;
  height: auto;
}

.sushi-item p {
  padding: 10px;
  color: #E9E9E9;
}

.sushi-item h3 {
  padding: 10px;
  color: #E9E9E9;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.price {
  font-size: 18px;
  color: #FF5C5C;
  font-weight: bold;
}

.sushi-item button {
  background-color: #88c057;  /* Light green background color */
  color: #333;  /* Darker text color for contrast */
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
}

.sushi-item button:hover {
  background-color: #7bb249;  /* Slightly darker green on hover */
}

.sushi-item button img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}


.app-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
}

.app-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}

.app-download {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.app-info {
  flex: 1;
  color: #E9E9E9;
}

.app-info h3 {
  margin-top: 0;
  font-size: 1.2em; /* Decreased font size */
}

.app-info p {
  margin: 10px 0;
  font-size: 0.9em; /* Decreased font size */
}

.app-info button {
  background-color: #88c057;
  color: #333;
  border: none;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.app-info button:hover {
  background-color: #7bb249;
}

.app-info button img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.app-image {
  flex: 1;
  max-width: 50%; /* Ensure the image takes half of the parent width */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



/* Media Queries */
@media (max-width: 1024px) {
  .icons {
    min-width: 70px;
  }
  .app-download {
    flex-direction: column;
  }

  .app-image {
    max-width: 100%; /* Full width on smaller screens */
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .app-info h3 {
    font-size: 1em; /* Smaller font size for tablets */
  }

  .app-info p {
    font-size: 0.8em; /* Smaller font size for tablets */
  }

  .app-info button {
    font-size: 12px; /* Smaller font size for tablets */
  }
  .icons {
    min-width: 60px;
  }
}

@media (max-width: 480px) {
  .app-info h3 {
    font-size: 0.9em; /* Smaller font size for mobile */
  }

  .app-info p {
    font-size: 0.7em; /* Smaller font size for mobile */
  }

  .app-info button {
    font-size: 10px; /* Smaller font size for mobile */
    padding: 8px;
  }

  .app-download {
    padding: 10px;
  }
}





.footer {
  background-color: #333;
  padding: 20px 0;
  color: #E9E9E9;
  text-align: center;
}

.footer a {
  color: #E9E9E9;
  text-decoration: none;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo {
  flex: 1;
  text-align: left;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;
}

.footer-logo h2 {
  margin: 10px 0;
}

.app-buttons img {
  width: 120px;
  margin: 10px 5px 0 0;
}

.footer-nav,
.footer-info,
.footer-social {
  flex: 1;
  text-align: left;
}

.footer-nav h3,
.footer-info h3,
.footer-social h3 {
  margin-top: 0;
}

.footer-nav ul {
  list-style-type: none;
  padding: 0;
}

.footer-nav ul li {
  margin: 5px 0;
}

.footer-nav ul li a {
  color: #E9E9E9;
  text-decoration: none;
}

.footer-nav ul li a:hover {
  text-decoration: underline;
}

.footer-info p {
  margin: 5px 0;
}

.social-icons a {
  margin-right: 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}


@media (max-width: 1300px) {
  .cart-container {
    width: 40% !important;
  }
}

@media (max-width: 1000px) {
  .cart-container {
    width: 50% !important;
  }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }
  .footer-social{
    text-align: right;
  }

  .footer-logo,
  .footer-nav,
  .footer-info,
  .footer-social {
    margin-bottom: 20px;
  }

  .cart-container {
    width: 80% !important;
  }
}


@media (max-width: 500px) {
.cart-container {
    width: 95% !important;
  }
}


@media (max-width: 360px) {
.cart-container {
    width: 100% !important;
  }
}



.cart-button {
  position: fixed;
  top: 160px;
  right: -10px;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 20px;
  border-radius: 20px 0 0 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out; /* Smooth transition */
}

.cart-button.visible {
  right: 0; /* Slide to the visible position */
}

.cart-button .cart-count {
  background-color: #88c057;
  color: #fff;
  border-radius: 50%;
  padding: 1px 5px;
  top: -6px;
  right: 2px;
  position: relative;
  font-size: 15px;
}

.cart-container {
  position: fixed;
  top: 60px;
  right: 0;
  width: 30%;
  max-height: 85%;
  background: url('assets/Group75-min(1).png') center center repeat-y, #202020;
  background-size: cover;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  background-size: 310%;
  z-index: 1001;
  overflow-y: auto;
  padding: 10px;
  border-radius: 20px 0 0 20px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}



.margin-top-h {
  margin-top: 14px !important;
  margin-bottom: 6px;

}

.language-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.selected-language {
  display: flex;
  align-items: center;
  background-color: #747474;
  border-radius: 8px;
  padding: 5px 10px;
  gap: 5px;
  transition: background-color 0.2s;
}

.selected-language:hover {
  background-color: #5a5a5a;
}

.language-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%; /* Circular flag */
}

.dropdown-menu {
  position: absolute;
  top: 50px; /* Aligns below the trigger */
  left: 0;
  background-color: #747474;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.dropdown-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
}

.dropdown-item img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.dropdown-item:hover {
  transform: scale(1.1); /* Enlarge on hover */
}

.language-icon-container {
  display: flex;
  justify-content: center;  /* Horizontally center */
  align-items: center;      /* Vertically center */
  height: 100%;             /* Ensure it takes full height of the parent container */
}



</style>
